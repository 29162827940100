<template>
  <div>
    <template v-if="isMap">
      <g-map />
    </template>
    <template v-else>
      <l-map />
    </template>
  </div>
</template>
<script>
export default {
  components: {
    GMap: () => import("./screens/GMap.vue"),
    LMap: () => import("./screens/LMap.vue"),
  },
  computed: {
    isMap() {
      return process.env.VUE_APP_GOOGLE_MAP_KEY ? true : false;
    },
  },
};
</script>
